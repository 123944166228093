<template>
  <div class="login_bg">
    <div class="login_content">
      <div class="login_logo"></div>
      <!-- 左侧区域 -->
      <div class="login_show"></div>
      <!-- 右侧区域 -->
      <div class="login_window">
        <p class="greet">Welcome to iMile,Reset your password!</p>
        <!-- <p
          :class="changeableBg"
          @click="
            flag = !flag;
            chooseBg($event);
          "
        ></p> -->
        <!-- 扫码登录 -->
        <div class="QR_box" v-show="flag">
          <div id="QR_code" v-show="flag">
            <!-- <img src="https://open.work.weixin.qq.com/wwopen/sso/qrImg?key=1b6572ca79f3e4ac" alt=""> -->
          </div>
        </div>
        <div class="scan_icon" v-show="flag"></div>
        <div class="login_tip" v-show="flag">
          Please use enterprise WeChat Scan QR code to log in
        </div>
        <!-- 表单登录 -->
        <!-- <div class="login_input" v-show="!flag">
          <el-form
            :rules="rules"
            ref="LoginFormRef"
            :inline="true"
            :label-position="labelPosition"
            label-width="80px"
            :model="formLabelAlign"
          >
            <el-form-item
              label="Account"
              class="changeTitlestyle"
              prop="username"
            >
              <el-input
                v-model="formLabelAlign.username"
                class="changeFormstyle"
                placeholder="please enter..."
              >
                <template slot="append">@imile.me</template>
              </el-input>
            </el-form-item>
            <el-form-item label="Password" class="changeTitlestyle">
              <el-input
                v-model="formLabelAlign.password"
                class="changeFormstyle"
                placeholder="please enter password... "
                show-password
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Wecom Identifying Code"
              class="changeTitlestyle"
            >
              <el-row>
                <el-input
                  v-model="formLabelAlign.code"
                  class="changeStyle"
                  style="width: 378px"
                  placeholder="please enter check code"
                >
                </el-input>
                <el-button
                  class="sendCode_btn"
                  @click="sendCode"
                  :disabled="disabled"
                  >{{ count }}</el-button
                >
              </el-row>
              <el-button
                v-show="buttonaction === 'login'"
                class="login_btn"
                @click="loginHome"
                >LOGIN</el-button
              >
              <el-button
                v-show="buttonaction === 'reset'"
                class="login_btn"
                @click="CommitPasswordReset"
                >reset</el-button
              >
            </el-form-item>
          </el-form>
          <ul class="login_more">
            <el-tooltip
              style="margin-top: 10px; color: #062cff"
              effect="light"
              placement="top-start"
            >
              <div slot="content" style="color: #0020d1">
                密码必须符合复杂性要求。不能包含用户的帐户名，不能包含用户姓名中超过两个连续字符的部分<br />至少有六个字符长,包含以下四类字符中的三类字符:英文大写字母(A
                到 Z)英文小写字母(a 到 z)<br />10 个基本数字(0 到
                9)非字母字符(例如 !、$、#、%)<br />
                <br />
                The password must meet the complexity requirements. The user's
                account name cannot be included, <br />and the user's name
                cannot contain more than two consecutive characters<br />
                At least six characters long, including three of the following
                four types of characters: English uppercase <br />letters (a to
                Z) English lowercase letters (a to Z) 10 basic numbers (0 to 9)
                <br />non alphabetic characters (e.g.!, $, #,%)
              </div>
              <el-button>password help</el-button>
            </el-tooltip>

            <el-button
              v-show="buttonaction === 'login'"
              type="text"
              @click="resetPassword('reset')"
              >resetPassword</el-button
            >
            <el-button
              v-show="buttonaction === 'reset'"
              type="text"
              @click="resetPassword('login')"
              >go to login</el-button
            >
          </ul>
        </div> -->
        <!-- 表单end -->
        <!-- 右侧区域end -->
      </div>
    </div>
    <div class="mail">Copyright © 2021 iMile Inc.All rights reserved</div>
  </div>
</template>

<script>
// eslint-disable-next-line
/* eslint-disable */
import axios from "axios";
import config from "@/config";
import { Message } from "element-ui";
import cryptoJs from "crypto-js";
export const projectEnv = process.env.NODE_ENV;
export const baseurl = config.baseUrl[projectEnv];
export const baseApiUrl = config.baseApiUrl[projectEnv];
export const cookiename = config.cookieName[projectEnv];
console.log(projectEnv);
console.log(baseurl);
console.log(baseApiUrl);
export const passwordReset = (params) => {
  return axios.post(`${baseApiUrl}/v1/user/reset/password.json`, params);
};
export const getLoginCode = (params) => {
  return axios.get(`${baseApiUrl}/v1/user/code`, { params: params });
};
export default {
  data() {
    // 验证邮箱规则
    return {
      // // 切换图标
      flag: true,
      buttonaction: "login",
      changeableBg: "login_toggle",
      LoginFormself: "LoginFormself",
      // 表单属性
      labelPosition: "top",
      // 验证码倒计时
      count: "SEND",
      disabled: false,
      waitTime: 59,
      formLabelAlign: {
        username: "",
        password: "",
        code: "",
      },
      rules: {},
    };
  },
  mounted() {
    if (this.$router.history.current.query.resetpassword) {
      this.flag = false;
      this.buttonaction = "reset";
    }
    this.wechat_render();
  },
  methods: {
    resetPassword(value) {
      this.buttonaction = value;
      if (value === "reset") {
        this.$router.push({ path: "/reset" });
      }
    },
    encryption(word) {
      var key = cryptoJs.enc.Utf8.parse("dffefere3324dfds");
      var password = cryptoJs.enc.Utf8.parse(word);
      var encrypted = cryptoJs.AES.encrypt(password, key, {
        mode: cryptoJs.mode.ECB,
        padding: cryptoJs.pad.Pkcs7,
      });
      var encryptedPwd = encrypted.toString();
      var decrypt = cryptoJs.AES.decrypt(encryptedPwd, key, {
        mode: cryptoJs.mode.ECB,
        padding: cryptoJs.pad.Pkcs7,
      });
      var testDecryptStr = cryptoJs.enc.Utf8.stringify(decrypt).toString();
      return encryptedPwd;
    },
    // 切换登录小图标
    chooseBg(e) {
      if (e.target.className.indexOf("toggle_formbg") === -1) {
        this.changeableBg = "login_toggle toggle_formbg";
      } else {
        this.changeableBg = "login_toggle";
      }
    },
    // 发送验证码
    sendCode() {
      if (
        typeof this.formLabelAlign.username == "undefined" ||
        this.formLabelAlign.username == null ||
        this.formLabelAlign.username == ""
      ) {
        this.$message.success("you code is send to you email");
      } else {
        this.disabled = true;
        this.count = `${this.waitTime}s`;
        const timer = setInterval(() => {
          if (this.waitTime > 1) {
            this.waitTime--;
            this.count = `${this.waitTime}s`;
          } else {
            clearInterval(timer);
            this.count = "SEND";
            this.disabled = false;
            this.waitTime = 59;
          }
        }, 1000);
        getLoginCode({
          username: this.formLabelAlign.username + "@imile.me",
          code_type: this.buttonaction,
        }).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("your code is send to you email");
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    // 账号密码登录
    loginHome() {
      this.$refs.LoginFormRef.validate(async (valid) => {
        if (!valid) return;
        var formcopy = JSON.parse(JSON.stringify(this.formLabelAlign));
        //深度拷贝原来的form表单，避免更改原来表单内容
        formcopy["password"] = this.encryption(formcopy["password"]);
        const { data: res } = await this.$http.post(
          `${baseApiUrl}` + "/v1/user/account.json",
          formcopy
        );
        console.log(res);
        if (res.code === 200) {
          document.cookie =
            "tduss_m_v2=" + res.token + ";domain=.imile-inc.com";
          this.$message.success(
            "Successful login, please visit your own domain name"
          );
          let nexturl = "";
          console.log(this.$router.history.current.query.nexturl);
          if (this.$router.history.current.query.nexturl) {
            nexturl = this.$router.history.current.query.nexturl;
            location.href = nexturl;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    CommitPasswordReset() {
      console.log("reset");
      var pwdRegex = new RegExp(
        "(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}"
      );
      var otherRegex = new RegExp(
        "(?=.*[0-9])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,30}"
      );
      var secondRegex = new RegExp(
        "(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.*[a-z]).{8,30}"
      );
      var thirdRegex = new RegExp("(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,30}");
      var password = this.formLabelAlign.password;
      if (this.formLabelAlign.code == "") {
        this.$message.error("验证码不能为空");
      }
      if (
        !pwdRegex.test(password) &&
        !thirdRegex.test(password) &&
        !otherRegex.test(password) &&
        !secondRegex.test(password)
      ) {
        this.$message.error(
          "Your password complexity is too low (the password must contain uppercase or lowercase letters, numbers,or special characters), please change the password in time!"
        );
      } else {
        this.$refs.LoginFormRef.validate(async (valid) => {
          var formcopy = JSON.parse(JSON.stringify(this.formLabelAlign));
          //深度拷贝原来的form表单，避免更改原来表单内容
          formcopy["password"] = this.encryption(formcopy["password"]);
          const { data: res } = await this.$http.post(
            `${baseApiUrl}` + "/v1/user/reset/password.json",
            formcopy
          );
          console.log(res);
          if (res.code === 200) {
            document.cookie =
              cookiename + "=" + res.token + ";domain=.imile-inc.com";
            this.$message.success("your have reset you password success");
          } else {
            this.$message.error(res.message);
          }
        });
      }
      // } else {
      //   this.$refs.LoginFormRef.validate(async valid => {
      //     var formcopy = JSON.parse(JSON.stringify(this.formLabelAlign));
      //     //深度拷贝原来的form表单，避免更改原来表单内容
      //     formcopy['password'] = this.encryption(formcopy['password'])
      //     const { data: res } = await this.$http.post(`${baseApiUrl}` + '/v1/user/reset/password.json', formcopy)
      //     console.log(res)
      //     if (res.code === 200) {
      //       document.cookie= cookiename + "=" + res.token + ";domain=.imile-inc.com"
      //       this.$message.success("you have reset you password success")
      //     } else {
      //       this.$message.error(res.message)
      //     }
      //   })
      // }
    },
    wechat_render() {
      let nexturl = "";
      if (this.$router.history.current.query.nexturl) {
        nexturl = this.$router.history.current.query.nexturl;
      }
      window.WwLogin({
        self_redirect: true,
        id: "QR_code",
        scope: "snapi_login",
        response_type: "code",
        appid: "ww39446074a72f98e0",
        agentid: "1000015",
        redirect_uri: encodeURIComponent(
          baseurl + "/#/redirect?nexturl=" + nexturl
        ),
        href: "data:text/css;base64,LmltcG93ZXJCb3ggeyB3aWR0aDoxODBweDttYXJnaW4tdG9wOjEwcHg7bWFyZ2luLWxlZnQ6IDMwcHg7fQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7IHdpZHRoOiAxODBweDtoZWlnaHQ6IDE4MHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmU7fQ0KLmltcG93ZXJCb3ggLnN0YXR1cyB7ZGlzcGxheTogbm9uZTt9",
      });
    },
  },
};
</script>

<style scoped>
/* 二维码 */
.impowerBox {
  width: 180px;
  margin-top: 10px;
  margin-left: 30px;
}
.impowerBox .qrcode {
  width: 180px;
  height: 180px;
}
.impowerBox .title {
  display: none;
}
.impowerBox .info {
  display: none;
}
.status_icon {
  display: none;
}
.impowerBox .status {
  display: none;
}
/* end */
/deep/.el-form-item .el-form-item__error {
  top: 44px;
  left: 100%;
  transform: translate(-100%);
  padding-top: 0;
  width: 300px;
  text-align: right;
}
.el-input::placeholder {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #c9ccd8;
  line-height: 18px;
}
.el-button .disabled-style {
  background-color: #8098ff;
  color: #fff;
}
/* 显示页面 */
.login_bg {
  width: 100%;
  height: 100%;
}
.login_bg .login_content {
  position: relative;
  margin: 60px auto;
  width: 1200px;
  min-width: 1200px;
  height: 680px;
  background-color: #f6f8fb;
  border-radius: 12px;
}
/* 表单输入区域 */
.login_input {
  width: 100%;
  height: 350px;
  margin-top: 72px;
}
.changeTitlestyle {
  height: 70px;
  padding: 0;
}
.changeFormstyle {
  margin: 0;
  height: 40px;
  width: 480px;
  margin-bottom: 10px;
}
/* imile logo */
.login_content .login_logo {
  position: absolute;
  left: 470px;
  bottom: 640px;
  width: 260px;
  height: 80px;
  background: url("../assets/logo.png") no-repeat;
}
/* 左侧区域 */
.login_show {
  float: left;
  width: 500px;
  height: 100%;
  background: url("../assets/backgroud.png") no-repeat;
}
/* 右侧区域 */
.login_window {
  float: right;
  margin-right: 120px;
  margin-top: 120px;
  width: 480px;
  height: 422px;
}
/* 问候语 */
.login_window .greet {
  float: left;
  width: 377px;
  height: 32px;
  font-family: SFUIDisplay-Heavy;
  line-height: 32px;
  font-size: 30px;
  margin: 0;
}
/* 登录切换图标 */
.login_window .login_toggle {
  float: right;
  width: 44px;
  height: 40px;
  background: url("../assets/logo1.png") no-repeat;
  margin: 0;
}
.login_window .toggle_formbg {
  background: url("../assets/logo2.png") no-repeat;
}
.login_toggle:hover {
  cursor: pointer;
}
.login_window .scan_icon {
  position: absolute;
  top: 502px;
  right: 440px;
  width: 40px;
  height: 40px;
  background: #cbd0dd url("../assets/scan.png") no-repeat;
  border-radius: 8px;
}
/* 二维码盒子 */
.login_window .QR_box {
  position: absolute;
  top: 232px;
  right: 240px;
  width: 240px;
  height: 240px;
  background-color: #fff;
}
/* 二维码内容 */
.QR_box .QR_code {
  margin: 30px;
  width: 180px;
  height: 180px;
  background-color: #353a53;
}
.QR_code img {
  width: 100%;
  height: 100%;
}
/* 登录提示语句 */
.login_window .login_tip {
  position: absolute;
  top: 502px;
  right: 240px;
  width: 190px;
  height: 40px;
  color: #797e8f;
  font-family: SFUIDisplay-Regular;
  font-size: 14px;
  line-height: 20px;
}
/* imile 邮箱 */
.login_bg .mail {
  position: fixed;
  top: 880px;
  left: 50%;
  transform: translate(-50%);
  font-size: 14px;
  line-height: 16px;
  font-family: SFUIDisplay-Regular;
  color: #ffffff;
}
/* 帮助链接 */
.login_more {
  display: flex;
  justify-content: space-between;
  margin-top: 77px;
}
.login_more li a {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #797e8f;
  line-height: 18px;
}
/* 发送验证码的按钮 */
.sendCode_btn {
  width: 102px;
  height: 42px;
  background: #062cff;
  color: #fff;
}
.login_btn {
  width: 480px;
  height: 48px;
  background: #062cff;
  color: #fff;
  margin-top: 30px;
}
/* 登录按钮 */
</style>
