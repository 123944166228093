<template>
<div class="login_bg">
    <div class="login_content">
      <div class="login_logo"></div>
      <!-- 左侧区域 -->
      <div class="login_show"></div>
      <!-- 右侧区域 -->
        <div class="login_window">
            <p class="greet">Welcome to iMile,Reset your password!</p>
            <!-- 微信表单切换 -->
            <p :class="changeableBg" @click="flag=!flag;chooseBg($event)"></p>
            <!-- 扫码登录 -->
            <div class="QR_box" v-show="flag">
              <div id="QR_code" v-show="flag">
                <!-- <img src="https://open.work.weixin.qq.com/wwopen/sso/qrImg?key=1b6572ca79f3e4ac" alt=""> -->
              </div>
            </div>
            <div class="scan_icon" v-show="flag"></div>
            <div class="login_tip" v-show="flag">Please use enterprise WeChat Scan QR code to log in</div>
        <!-- 表单登录 -->
        <div class="login_input" v-show="!flag">
        <el-form :rules="rules" ref="LoginFormRef" :inline="true" :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
            <!-- 用户 -->
            <el-form-item label="Account" class="changeTitlestyle" prop="username" >
                   <el-input v-model="formLabelAlign.username" class="changeFormstyle" placeholder="please enter..."></el-input>
           </el-form-item>
           <!-- 密码 -->
                <el-form-item label="Password" class="changeTitlestyle">
                   <el-input v-model="formLabelAlign.password" class="changeFormstyle" placeholder="please enter password... " show-password clearable></el-input>
           </el-form-item>
           <!-- 验证码 -->
                <el-form-item label="Wecom Identifying Code" class="changeTitlestyle">
                  <el-row>
                   <el-input v-model="formLabelAlign.code" class="changeStyle" style="width:378px" placeholder="please enter password...">
                   </el-input>
                   <el-button class="sendCode_btn" @click="sendCode" :disabled="disabled">{{count}}</el-button>
                   </el-row>
                   <!-- 登录按钮 -->
                   <el-button class="login_btn" @click="loginHome">LOGIN</el-button>
           </el-form-item>
     </el-form>
      <!-- 帮助链接start -->
      <ul class="login_more">
       <li class="helpWord"><a href="#">Help Document</a></li>
       <li class="forgetPsd"><a href="#">Forget Password?</a></li>
     </ul>
       </div>
     <!-- 表单end -->
      <!-- 右侧区域end -->
      </div>
      </div>
     <div class="mail">Copyright © 2021 iMile Inc.All rights reserved</div>
     </div>
</template>

<script>
// eslint-disable-next-line
/* eslint-disable */
import axios from "axios";
import config from '@/config'
export const projectEnv = process.env.NODE_ENV
export const baseurl = config.baseUrl[projectEnv]
export const baseApiUrl = config.baseApiUrl[projectEnv]
export const cookieName = config.cookieName[projectEnv]
export const Wlogin = params => { return axios.get(`${baseApiUrl}/v1/user/token.get`, {params: params})}
export default ({
  data () {
    // 验证邮箱规则
    var checkEmail = (rules, value, cb) => {
      const regEmail = /^([a-zA-Z0-9.*])+@([a-zA-Z])+(\.[a-zA-Z0-9])+/
      if (regEmail.test(value)) {
        return cb()
      }
      cb(new Error('Wrong user name'))
    }
    return {
      // // 切换图标
      flag: true,
      changeableBg: 'login_toggle',
      LoginFormself: 'LoginFormself',
      // 表单属性
      labelPosition: 'top',
      // 验证码倒计时
      count: 'SEND',
      disabled: false,
      waitTime: 59,
      formLabelAlign: {
        username: 'pink@imile.me',
        password: 'Aa8888',
        code: ''
      },
      rules: {
        username: [{ validator: checkEmail, trigger: 'blur' }]
      }
    }
  },
  mounted () {
    let nexturl = ''
    let code = ''
    if (this.$router.history.current.query.code) {
      code = this.$router.history.current.query.code
      console.log(code)

      Wlogin({"code": code}).then(res => {
        console.log(res.data)
        if (res.data.code === 400) {
          this.$Message.success("登录失败，错误原因为" +  res.data.message)
        } else {
          document.cookie= "tduss_m_v2" + "=" + res.data.token + ";domain=.imile-inc.com"
        }
        if (this.$router.history.current.query.nexturl) {
          nexturl = this.$router.history.current.query.nexturl
          window.location.href=nexturl
        } else {
          this.$Message.success("未找到前往地址")
        }
      })
    }
    if (this.$router.history.current.query.nexturl) {
      nexturl = this.$router.history.current.query.nexturl
    } else {
      this.$Message.success("未找到前往地址")
    }

    // this.wechat_render()
  },
  methods: {

    // 切换登录小图标
    chooseBg (e) {
      if (e.target.className.indexOf('toggle_formbg') === -1) {
        this.changeableBg = 'login_toggle toggle_formbg'
      } else {
        this.changeableBg = 'login_toggle'
      }
    },
    // 发送验证码
    sendCode () {
      this.disabled = true
      this.count = `${this.waitTime}s`
      const timer = setInterval(() => {
        if (this.waitTime > 1) {
          this.waitTime--
          this.count = `${this.waitTime}s`
        } else {
          clearInterval(timer)
          this.count = '重新发送'
          this.disabled = false
          this.waitTime = 59
        }
      }, 1000)
      this.$refs.LoginFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(`${baseApiUrl}` + '/api/v1/user/account.json', this.formLabelAlign)
        console.log(res)
        // if (res.code === 200) {}
      })
    },
    // 账号密码登录
    loginHome () {
      this.$refs.LoginFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(`${baseApiUrl}` + '/v1/user/account.json', this.formLabelAlign)
        console.log(res)
        if (res.code !== 200) return
        window.sessionStorage.setItem('token', res.token)
        // 跳转新页面
        this.$router.push('/home')
      })
    },
    wechat_render () {
      let nexturl = ''
      if (this.$router.history.current.query.nexturl) {
        nexturl = this.$router.history.current.query.nexturl
      }
      window.WwLogin({
        self_redirect: true,
        id: 'QR_code',
        scope: 'snapi_login',
        response_type: 'code',
        appid: 'ww39446074a72f98e0',
        agentid: '1000015',
        redirect_uri: encodeURIComponent(baseurl + '/redirect?nexturl=' + nexturl),
        href: 'data:text/css;base64,LmltcG93ZXJCb3ggeyB3aWR0aDoxODBweDttYXJnaW4tdG9wOjEwcHg7bWFyZ2luLWxlZnQ6IDMwcHg7fQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7IHdpZHRoOiAxODBweDtoZWlnaHQ6IDE4MHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmU7fQ0KLmltcG93ZXJCb3ggLnN0YXR1cyB7ZGlzcGxheTogbm9uZTt9'
      })
    }
  }
})
</script>

<style scoped>
/* 二维码 */
.impowerBox { width:180px;margin-top:10px;margin-left: 30px;}
.impowerBox .qrcode { width: 180px;height: 180px;}
.impowerBox .title {display: none;}
.impowerBox .info {display: none;}
.status_icon {display: none;}
.impowerBox .status {display: none;}
/* end */
/deep/.el-form-item .el-form-item__error {
  top: 44px;
  left: 100%;
  transform: translate(-100%);
  padding-top: 0;
  width: 300px;
  text-align: right;
}
.el-input::placeholder {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #C9CCD8;
  line-height: 18px;
}
.el-button .disabled-style {
    background-color: #8098FF;;
    color: #fff;
}
/* 显示页面 */
.login_bg {
  width: 100%;
  height: 100%;
}
.login_bg .login_content {
  position: relative;
  margin:160px auto;
  width: 1200px;
  min-width:1200px;
  height: 680px;
  background-color: #F6F8FB;
  border-radius: 12px;
}
/* 表单输入区域 */
.login_input {
  width: 100%;
  height: 350px;
  margin-top: 72px;
}
.changeTitlestyle {
  height: 70px;
  padding: 0;
}
.changeFormstyle {
  margin: 0;
  height: 48px;
  width: 480px;
  margin-bottom: 10px;
  }
/* imile logo */
.login_content .login_logo {
  position: absolute;
  left: 470px;
  bottom: 640px;
  width: 260px;
  height: 80px;
  background: url('../assets/logo.png') no-repeat;
}
/* 左侧区域 */
.login_show {
  float: left;
  width: 500px;
  height: 100%;
  background: url('../assets/backgroud.png') no-repeat;
}
/* 右侧区域 */
.login_window {
  float: right;
  margin-right: 120px;
  margin-top: 120px;
  width: 480px;
  height: 422px;
    }
    /* 问候语 */
  .login_window .greet {
  float: left;
  width: 377px;
  height: 32px;
  font-family: SFUIDisplay-Heavy;
  line-height: 32px;
  font-size: 30px;
  margin: 0;
}
/* 登录切换图标 */
.login_window .login_toggle {
    float: right;
    width: 44px;
    height: 40px;
    background: url('../assets/logo1.png') no-repeat;
    margin: 0;
}
.login_window .toggle_formbg {
  background: url('../assets/logo2.png') no-repeat;
}
.login_toggle:hover {
  cursor: pointer;
}
.login_window .scan_icon {
  position: absolute;
  top: 502px;
  right: 440px;
  width: 40px;
  height: 40px;
  background: #CBD0DD url('../assets/scan.png') no-repeat;
  border-radius: 8px;
}
/* 二维码盒子 */
.login_window .QR_box {
  position: absolute;
  top:232px;
  right: 240px;
  width: 240px;
  height: 240px;
  background-color: #fff;
}
/* 二维码内容 */
.QR_box .QR_code {
  margin: 30px;
  width: 180px;
  height: 180px;
  background-color: #353A53;
}
.QR_code img {
  width: 100%;
  height: 100%;
}
/* 登录提示语句 */
.login_window .login_tip {
  position: absolute;
  top: 502px;
  right: 240px;
  width: 190px;
  height: 40px;
  color: #797E8F;
  font-family: SFUIDisplay-Regular;
  font-size: 14px;
  line-height: 20px;
}
/* imile 邮箱 */
.login_bg .mail {
  position: fixed;
  top: 880px;
  left: 50%;
  transform: translate(-50%);
  font-size: 14px ;
  line-height: 16px ;
  font-family:  SFUIDisplay-Regular;
  color: #FFFFFF;
}
/* 帮助链接 */
.login_more {
  display: flex;
  justify-content: space-between;
  margin-top: 77px;
}
.login_more li a{
font-family: PingFangSC-Regular;
font-size: 14px;
color: #797E8F;
line-height: 18px;
}
/* 发送验证码的按钮 */
.sendCode_btn {
  width:102px;
  height:42px;
  background:#062CFF;
  color:#fff;
}
.login_btn {
  width:480px;
  height:48px;
  background:#062CFF;
  color:#fff;
  margin-top:30px;
}
/* 登录按钮 */
</style>
