import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import redirect from '@/components/redirect'
import resetpassword from '@/components/resetpassword'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: 'reset' },
  { path: '/login', component: Login },
  { path: '/redirect', component: redirect },
  { path: '/reset', component: resetpassword }
]

const router = new VueRouter({
  routes
})

export default router
